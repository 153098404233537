//  https://gka.github.io/palettes/#/9|s|00429d,96ffea,ffffe0|ffffe0,ff005e,93003a|1|1

// #006cff, #ff76f6, #ffd27d
const LIGHT_RANGE = ['#006cff', '#196cff', '#286cff', '#326dff', '#396dfe', '#416dfe', '#476dfe', '#4d6efe', '#526efe', '#576efd', '#5c6efd', '#606ffd', '#656ffd', '#696ffc', '#6d70fc', '#7070fc', '#7470fb', '#7771fb', '#7b71fb', '#7e71fa', '#8172fa', '#8572fa', '#8872f9', '#8b73f9', '#8e73f8', '#9074f8', '#9374f7', '#9674f7', '#9975f7', '#9b75f6', '#9e76f6', '#a076f5', '#a377f4', '#a577f4', '#a778f3', '#aa78f3', '#ac79f2', '#ae79f2', '#b17af1', '#b37af0', '#b57bf0', '#b77cef', '#b97cee', '#bb7dee', '#bd7ded', '#bf7eec', '#c17feb', '#c37feb', '#c580ea', '#c781e9', '#c981e8', '#ca82e7', '#cc83e6', '#ce84e6', '#d084e5', '#d185e4', '#d386e3', '#d487e2', '#d688e1', '#d888e0', '#d989df', '#db8ade', '#dc8bdd', '#de8cdc', '#df8ddb', '#e08eda', '#e28fd9', '#e390d8', '#e491d7', '#e692d5', '#e793d4', '#e894d3', '#e995d2', '#eb96d1', '#ec97cf', '#ed98ce', '#ee99cd', '#ef9acc', '#f09bca', '#f19cc9', '#f29dc8', '#f39ec6', '#f49fc5', '#f5a1c3', '#f6a2c2', '#f6a3c0', '#f7a4bf', '#f8a5bd', '#f9a7bc', '#f9a8ba', '#faa9b9', '#fbaab7', '#fbacb5', '#fcadb4', '#fdaeb2', '#fdafb0', '#feb1af', '#feb2ad', '#feb3ab', '#ffb5a9', '#ffb6a7', '#ffb8a5', '#ffb9a3', '#ffbba1', '#ffbc9f', '#ffbe9c', '#ffbf9a', '#ffc198', '#ffc296', '#ffc494', '#ffc592', '#ffc78f', '#ffc88d', '#ffca8b', '#ffcb89', '#ffcc86', '#ffce84', '#ffcf82', '#ffd17f', '#ffd27d']

// #000a90, #8a292b, #cb5728
const DARK_RANGE = ['#2f0592', '#340490', '#38038f', '#3c038d', '#3f028c', '#42018a', '#450088', '#480087', '#4a0086', '#4c0084', '#4e0083', '#510082', '#530081', '#54007f', '#56007e', '#58007d', '#5a007b', '#5c007a', '#5d0079', '#5f0078', '#610076', '#620075', '#640074', '#660072', '#670071', '#69006f', '#6a006e', '#6c006d', '#6d006b', '#6f006a', '#700069', '#710067', '#730066', '#740064', '#750063', '#770061', '#780060', '#79005f', '#7b005d', '#7c005c', '#7d005a', '#7f0059', '#800058', '#810056', '#820055', '#830053', '#850052', '#860050', '#87004f', '#88004d', '#89004c', '#8a004a', '#8c0049', '#8d0047', '#8e0046', '#8f0145', '#8f0244', '#900343', '#910442', '#920540', '#93063f', '#94073e', '#94083d', '#95093c', '#960a3b', '#970b3a', '#970c39', '#980d38', '#990e37', '#9a0f36', '#9a1035', '#9b1234', '#9c1333', '#9c1432', '#9d1431', '#9e1530', '#9f162f', '#9f172e', '#a0182d', '#a0192c', '#a11a2b', '#a21b2a', '#a21c2a', '#a31d29', '#a31e28', '#a41f27', '#a52026', '#a52125', '#a62224', '#a62324', '#a72323', '#a72422', '#a82521', '#a82621', '#a92720', '#a9281f', '#aa291f', '#aa2a1e', '#ab2b1d', '#ab2c1d', '#ab2d1c', '#ac2e1c', '#ac2f1b', '#ad301b', '#ad311a', '#ad321a', '#ae331a', '#ae3419', '#ae3519', '#ae3619', '#af3719', '#af3719', '#af3919', '#af3a19', '#b03b19', '#b03c1a', '#b03d1a', '#b03e1b', '#b03f1b', '#b0401c']

const BOX_SHADOWS = [
  '0 0 45px 23px #fff, 0 0 75px 45px #f0f, 0 0 105px 77px #0ff',
  '0 0 45px 23px #fff, 0 0 75px 45px #ff00cc, 0 0 105px 77px #00ffd2',
  '0 0 45px 23px #fff, 0 0 75px 45px #b400ff, 0 0 105px 77px #00aeff',
]

module.exports = {
  LIGHT_RANGE,
  DARK_RANGE,
  BOX_SHADOWS,
}
